import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Button from "../components/atoms/button";

function DesignButton() {
  return (
    <div className="design__content">
      <SEO title="Design System - Button" />
      <h2>Buttons</h2>
      <p className="design__text p2">
        Buttons represent all CTAs (call to action) on Buffy branded websites.
        This includes links to pages, products, collections and to ATCs (add to
        cart).
      </p>

      <div className="design__stories">
        <div className="design__story-section">
          <h5>Default Buttons</h5>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "lightEucalyptus",
              route: "https://buffy.co",
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "buffyOrange",
              route: "https://buffy.co",
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "buffyPurple",
              route: "https://buffy.co",
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              route: "https://buffy.co",
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "orange",
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "green",
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "blue",
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "yellow",
            }}>
            Meet The Breeze
          </DesignStory>
        </div>

        <div className="design__story-section">
          <h6>Inactive</h6>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              inactive: true,
            }}>
            Meet The Breeze
          </DesignStory>
        </div>

        <div className="design__story-section">
          <h5>Compact Buttons</h5>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "lightEucalyptus",
              compact: true,
            }}>
            Buy Now
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "buffyOrange",
              compact: true,
            }}>
            Buy Now
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              compact: true,
            }}>
            Buy Now
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "orange",
              compact: true,
            }}>
            Buy Now
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "green",
              compact: true,
            }}>
            Buy Now
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "blue",
              compact: true,
            }}>
            Buy Now
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "yellow",
              compact: true,
            }}>
            Buy Now
          </DesignStory>
        </div>

        <div className="design__story-section">
          <h6>Inactive</h6>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              compact: true,
              inactive: true,
            }}>
            Buy Now
          </DesignStory>
        </div>

        <div className="design__story-section">
          <h5>Outlined</h5>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "lightEucalyptus",
              outlined: true,
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "buffyOrange",
              outlined: true,
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              outlined: true,
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "black",
              outlined: true,
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              compact: true,
              outlined: true,
            }}>
            Buy Now
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "black",
              compact: true,
              outlined: true,
            }}>
            Buy Now
          </DesignStory>
        </div>

        <div className="design__story-section">
          <h6>Inactive</h6>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              inactive: true,
              outlined: true,
            }}>
            Meet The Breeze
          </DesignStory>
          <DesignStory
            type="component"
            component={Button}
            className="shared__button--spacing"
            compact
            props={{
              color: "purple",
              compact: true,
              inactive: true,
              outlined: true,
            }}>
            Buy Now
          </DesignStory>
        </div>

        <div className="design__story-section">
          <DesignProps component={Button} />
        </div>
      </div>
    </div>
  );
}

export default DesignButton;
